html
    +below('m')
        font-size 90%

    +below('s')
        font-size 85%

body
    font-family $font-family-sans-serif;
    font-weight 300
    font-size 16px
    color #686868
    line-height 1.6
    // lost-utility edit

a
    color #686868
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd

.main-color-gradient
    height 7px
    width 100%
    background-color: red;
    background-image: linear-gradient(to right, #eb6049, #eb6049 20%, #fab536 20% , #fab536 40%, #2db9ba 40% , #2db9ba 60%, #48b059 60% , #48b059 80%, #d7649f 80% , #d7649f 100% );


&:nth-child(1) a .box
    background #eb6049
&:nth-child(2) a .box
    background #fab536
&:nth-child(3) a .box
    background #2db9ba
&:nth-child(4) a .box
    background #48b059
&:nth-child(5) a .box
    background #d7649f