.footer
    lost-utility clearfix
    background #455158
    padding 0.5em 0 1em
    color #fff
    text-align center

    a
        color #fff
        text-decoration underline

.copyright
    background #252525
    text-align center
    color #fff
    padding .9em 0 .7em
    font-size .8rem
    p
        margin 0

    a
        color inherit