.partners
  list-style none
  margin 0
  padding 0
  .partner-container
    lost-column 1/1
    margin-bottom 2em
    &:first-child .partner
      border none
    .partner
      color #686868
      lost-column 1/1
      border-top 1px #f2f2f2 solid
      padding-top 2em
      a, a:hover, a:link
        color inherit
      &--image
        lost-column 1/2
        text-align right
        +below('s')
          text-align left
          margin-bottom 1.5rem
          lost-column 1/1
        img
          width 50%
          +below('l')
            width 75%
      &--title
        font-size 2rem
        font-weight 300
      &--contact
        padding 1rem 0
      &--content
        lost-column 1/2
        +below('s')
          lost-column 1/1