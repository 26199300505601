.subpages
  list-style none
  padding 0
  margin 0
  margin-top 3em
  .subpage-container
    lost-column 1/3
    +below('l')
      lost-column 1/2
    +below('s')
      lost-column 1/1
    margin-bottom 3em
    a
      display: block
      height 100%
      width 100%
      .subpage
        background #eb6049
        color #fff
        box-shadow 2px 2px 6px #d9d9d9
        &--image
          width: 100%
          img
            width 100%
        &--title, &--subtitle
          font-size 1.1rem
          +below('m')
            font-size 1rem
          display: block
        &--title
          padding 0.25em 1em 0em
          font-weight 500
        &--subtitle
          padding 0em 1em 0.25em
        &--description
          padding 0.8em 1em 0.5em
          background white
          width 100%
          display block
          color #737373
          font-size 0.9rem
          min-height 140px
          +below('l')
            min-height 120px
          +below('s')
            min-height 0
          p
            margin-bottom .5em

    .subpage-after
      padding 0.25em 0em
      text-align right
      color #6f6f6f
      font-size 0.95rem
      margin-top .5em
      i
        padding-right 0.25em

    &:nth-child(1) a .subpage, &:nth-child(6) a .subpage
      background #eb6049
    &:nth-child(2) a .subpage, &:nth-child(7) a .subpage
      background #fab536
    &:nth-child(3) a .subpage, &:nth-child(8) a .subpage
      background #2db9ba
    &:nth-child(4) a .subpage, &:nth-child(9) a .subpage
      background #48b059
    &:nth-child(5) a .subpage, &:nth-child(10) a .subpage
      background #d7649f

.subpage-detail
  .subpage
    &--image
      lost-column 2/5
      margin-top 6rem
      img
        width 100%
      +below('l')
        lost-column 1/1
        margin-top 1rem
    &--content
      lost-column 3/5
      +below('l')
        lost-column 1/1
        margin-top 1.5rem