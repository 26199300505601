@import 'bootstrap/forms'

#form
    lost-column 2/3


.form-group
    label
        margin-bottom 2px
        font-weight 500

.form-control:focus
    box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 3px rgba(64,72,85,.6)

.bs-callout
    padding 10px
    margin 20px 0
    border 1px solid #eee
    border-left-color #1b809e
    border-left-width 5px
    border-radius 3px