h1
  background url('icon_color.svg') no-repeat center right;
  background-size 2.5rem
  display: inline-block
  padding-right 3.4rem
  font-weight 300
  font-size 2rem
  color: #686868

h2
  background url('icon_dark_grey.svg') no-repeat bottom center;
  background-size 2.5rem
  font-weight 300
  font-size 1.8rem
  color: #686868
  text-align center
  padding-bottom 2.2rem

h3
  background url('icon_light_grey.svg') no-repeat bottom center;
  background-size 2.5rem
  font-weight 300
  font-size 1.8rem
  color: #d1d1d1
  text-align center
  padding-bottom 2.2rem

h4
  background-size 2.5rem
  display: inline-block
  font-weight 300
  font-size 1.8rem
  line-height 125%
  margin-bottom 1rem
  color: #686868
  +below('l')
    margin 1rem 0 0.8rem
    font-size 1.75rem
  +below('m')
    margin 0 0 0.8rem
    font-size 1.5rem
h5
  font-weight 500
  font-size 0.8rem
  margin-bottom 0
  color: #686868

.subpage--content i
  font-size 1.2rem
  color: #ea5f47