.header
    height 600px
    width 100%
    background url('lead.jpg') no-repeat center top/cover
    position fixed
    top 0
    color #fff
    z-index 1
    +below('l')
        height 500px
    +below('m')
        height 380px
    +below('s')
        height 300px
    &.header--small
        height 400px
        +below('s')
            height 300px
        &.header--collapsed
            height 75px
    .black-overlay
        height 100%
        width 100%
        background rgba(0,0,0,0.43);
        padding-top 1em
    &--collapsed
        z-index 3
        background #FFFFFF
        height 75px
        +below('s')
           height 65px !important
        box-shadow: 0 1px 6px rgba(0,0,0,.2)
        color #686868
        .black-overlay
            background none
            height 70px
            .teaser
                display none
        .logo
            .white
                display none  !important;
            .dark
                display block !important;

        .nav-main .nav-item:last-child .nav-link
            background #48b059
            color #fff
            border-radius 0
            margin-top -1em
            padding 25px 30px
            border none

.logo
    lost-column 3/12
    margin-top -3px
    +below('l')
        lost-column 1/2
    +below('s')
        lost-column 2/3
        margin-top -6px

    a
        color inherit
        display block
    img
        max-width 100%
    .dark
        display none

.teaser
    padding-top 6em
    padding-left 4.8em
    lost-column 3/4
    +below('m')
        padding-top 3em
    +below('s')
        lost-column 1/1
        padding-top 3em
        padding-left 0em
    .display-1
        font-size 3.3rem
        font-weight:300
        line-height 1.15
        padding-bottom 1rem
        +below('m')
            font-size 2.7rem
        +below('s')
            font-size 1.7rem
    .display-2
        font-size 1.4rem
        line-height 1.5
        lost-column 3/4
        +below('m')
            font-size 1.1rem
        +below('s')
            font-size 1.0rem

header.header--small
    .teaser
        padding-top 4em
        padding-left 4.8em
        +below('l')
            padding-top 3em
        +below('m')
            padding-top 3em
        +below('s')
            padding-top 3em
            padding-left 0em