/* IMAGE LIGHTBOX SELECTOR */

#imagelightbox
{
  cursor: pointer;
  position: fixed;
  z-index: 10000;

  -ms-touch-action: none;
  touch-action: none;

  -webkit-box-shadow: 0 0 3.125em rgba( 0, 0, 0, .75 ); /* 50 */
  box-shadow: 0 0 3.125em rgba( 0, 0, 0, .75 ); /* 50 */
}

/* OVERLAY */

#imagelightbox-overlay
{
  background-color: #fff;
  background-color: rgba( 255, 255, 255, .9 );
  position: fixed;
  z-index: 9998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


/* "CLOSE" BUTTON */

#imagelightbox-close
{
  width: 2.5em; /* 40 */
  height: 2.5em; /* 40 */
  text-align: left;
  background-color: #666;
  border-radius: 50%;
  position: fixed;
  z-index: 10002;
  top: 2.5em; /* 40 */
  right: 2.5em; /* 40 */

  -webkit-transition: color .3s ease;
  transition: color .3s ease;
}
#imagelightbox-close:hover,
#imagelightbox-close:focus
{
  background-color: #111;
}
#imagelightbox-close:before,
#imagelightbox-close:after
{
  width: 2px;
  background-color: #fff;
  content: '';
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 50%;
  margin-left: -1px;
}
#imagelightbox-close:before
{
  -webkit-transform: rotate( 45deg );
  -ms-transform: rotate( 45deg );
  transform: rotate( 45deg );
}
#imagelightbox-close:after
{
  -webkit-transform: rotate( -45deg );
  -ms-transform: rotate( -45deg );
  transform: rotate( -45deg );
}
