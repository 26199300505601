.site-wrapper
    top 600px
    position: relative
    z-index 2
    background #fff
    +below('l')
        top 500px
    +below('m')
        top 380px
    +below('s')
        top 300px
    &--small
        top 400px
        +below('l')
            top 380px
        +below('m')
            top 380px
        +below('s')
            top 300px


.main
    lost-utility clearfix
    padding 2em 0
    color #929292
    min-height 400px
    min-height 40vh

.content
    lost-column 1/1
    font-size 1rem
    line-height 1.5
    margin-top .5em
    &--full
        lost-column 1/1
    &--half
        lost-column 4/6
        padding-right 3em
        +below('m')
            lost-column 3/5
            padding-right 0
        +below('s')
            lost-column 1/1
    .teasertext
        font-size 1.2rem
        color: #686868
        a
            text-decoration underline
    h2+.teasertext
        text-align center

    p
        margin 0 0 1em

    .highlight
        lost-column 2/6
        background #455158
        color #ffffff
        padding 2rem 3rem
        +below('l')
            padding 1rem 1.5rem 1.5rem
        +below('m')
            lost-column 2/5
        +below('s')
            lost-column 1/1
            margin-top 2em
        &--title
            font-size 1.6rem
            padding-bottom 0.5em
            +below('m')
                font-size 1.4rem
        .highlight-items
            list-style none
            padding 0
            margin 0
            font-size 1.1rem
            +below('l')
                font-size .9rem
        .highlight-item:before
            content "-"
            padding-right 0.5em
