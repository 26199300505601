.nav-main
    lost-column 9/12 2
    +below('l')
        lost-column 1/2
    +below('s')
        lost-column 1/3

    .nav-items
        +below('l')
            display none

.nav-main
    text-align right

.nav-items
    margin 0
    padding 0

.nav-main .nav-item
    display inline-block
    padding 0
    margin 0
    position relative
    color inherit
    &:first-child
        margin-left 0

    +below('m')
        margin 0 1em

    &--active > .nav-link,
    &--child-active > .nav-link
        color inherit

    &:hover .nav-sub
        display block

    &:last-child
        margin-left: .8em;
        .nav-link
            border: 1px solid #fff
            border-radius 5px

.nav-link
    display block
    color inherit
    font-weight 300
    padding 0.2em .8em
    margin 0.2em 0em

.nav-main .nav-sub
    position absolute
    display none
    left 0
    top 51px
    min-width 250px
    box-shadow 0 2px 6px rgba(10, 10, 10, .05)
    .nav-item
        display block
        margin 0

    .nav-link
        display block
        margin 0
        padding .8em
