.reference-container, .main-reference-container
  padding 0em 0 2em
  background #f6f6f6
  .main-reference
    lost-column 1/1
    background #fff
    color #686868
    &--image
      lost-column 1/2
      +below('m')
        lost-column 1/1
      img
        width 100%
        display block
    &--content
      lost-column 1/2
      padding 3.5em 2em 0em 0
      +below('l')
        padding 2em 1em 0em 0
      +below('m')
        lost-column 1/1
        padding 1em
      &-company
        color #eb6049
        font-size 1.1rem
        +below('l')
          font-size 1rem
      &-title
        font-size 1.7rem
        +below('l')
          font-size 1.2rem
        line-height 1.4

  .box-after
    margin 0.5em 0em
    text-align right
    color #6f6f6f
    lost-column 1/1
    font-size 0.95rem
    i
      padding-right 0.25em
