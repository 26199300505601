.nav-404
  lost-column 9/12 2
  +below('l')
    lost-column 1/2
  +below('s')
    lost-column 1/3

  .nav-items
    +below('l')
      display none

.nav-items
  margin 0
  padding 0

.nav-404 .nav-item
  display inline-block
  padding 0
  margin 0
  position relative
  color inherit
  min-width 150px
  border-left 1px primary-color dotted
  &:first-child
    margin-left 0

  +below('m')
    margin 0 1em

  &--active > .nav-link,
  &--child-active > .nav-link
    color inherit

  .nav-link
    display block
    color inherit
    font-weight 300
    padding 0.2em .8em
    margin 0.2em 0em

.nav-404 .nav-sub
  margin 0
  padding 0
  .nav-item
    display block
    margin 0
    border-left none

  .nav-link
    display block
    margin 0
    padding .8em
