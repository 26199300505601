.solutions
  padding 2em 0
  display: block
.solution
  lost-column 1/1
  background #f6f6f6
  border 1px solid #ededed
  margin-bottom 8em
  +below('m')
    border none
    background none
  &--image,
  &--color
    lost-column 3/5
    line-height 0
    +below('l')
      lost-column 1/2
    +below('m')
      lost-column 1/1
    img
      width 100%
      max-width 100%
      height auto
      max-height 100%
      padding-bottom 1px

  &--reverse
    .solution--image
      lost-move 2/5
    .solution--content
      lost-move -3/5
    +below('l')
      .solution--image
        lost-move 1/2
      .solution--content
        lost-move -1/2
    +below('m')
      .solution--image,
      .solution--content
        lost-move 0

  &--content
    lost-column 2/5
    +below('l')
      lost-column 1/2
    +below('m')
      lost-column 1/1
      margin-top 4em
    &.first
      padding-left 30px
      +below('l')
        padding-left 15px
      +below('m')
        padding-left 0
    &.last
      padding-right 30px
      +below('l')
        padding-right 15px
      +below('m')
        padding-right 0

  &--color
    min-height 25vh
    vertical-align: bottom
    padding 2rem
    color #fff
    font-size 4rem

  &:nth-child(1) .solution--color, &:nth-child(6) .solution--color
    background #eb6049
  &:nth-child(2) .solution--color, &:nth-child(7) .solution--color
    background #fab536
  &:nth-child(3) .solution--color, &:nth-child(8) .solution--color
    background #2db9ba
  &:nth-child(4) .solution--color, &:nth-child(9) .solution--color
    background #48b059
  &:nth-child(5) .solution--color, &:nth-child(10) .solution--color
    background #d7649f